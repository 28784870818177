import { useQuery } from 'react-query';
import { LAMBDA_FORMS } from './constants';
import authenticatedApi from './authenticatedApi';

export const getRules = async (id?: string): Promise<any> => {
  if (!id) {
    return Promise.reject(new Error('Missing ID'));
  }

  return authenticatedApi.get<any>(`${LAMBDA_FORMS}/forms/${id}/rules`);
};

export const useRulesQuery = (id?: string) =>
  useQuery(['rules', id], () => getRules(id), {
    enabled: !!id,
    staleTime: 1000 * 60 * 3,
    cacheTime: 0,
  });
