import React from 'react';
import { Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthLogout from './auth/AuthLogout';
import AuthMain from './auth/AuthMain';
import AuthTokenRefresh from './auth/AuthTokenRefresh';
import DocAddPage from './pages/DocAdd';
import DocApiDownload from './doc-api/DocApiDownload';
import DocApiPreview from './doc-api/DocApiPreview';
import DocApiZip from './doc-api/DocApiZip';
import DocEditPage from './pages/DocEdit';
import DocSearchPage from './doc-search/DocSearchPage';
import DocViewPage from './doc-view/DocViewPage';
import MetadataExport from './pages/metadata-export/MetadataExport';
import OktaErrorHandler from './auth/OktaErrorHandler';
import PaperHeader from './Header';
import SecuredRoute from './components/SecuredRoute';
import { oktaConfig } from './auth/AuthConfig';

import './App.css';
import FormList from './pages/FormList';
import { useAppContext } from './Store';
import { userHasRole } from './utils/helper';
import LinkLoader from './pages/Link';
import { docEditDefaultValues, UserRoles } from './constants';
import NotFound from './pages/NotFound';
import OrgHierarchy from './pages/Admin/OrgHierarchy';
import Dictionary from './pages/Admin/Dictionary';

const CustomLoginCallback = () => <LoginCallback errorComponent={OktaErrorHandler} />;
const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const { state } = useAppContext();

  const oktaAuth = new OktaAuth(oktaConfig);
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <PaperHeader />
        <Routes>
          <Route
            path="/search"
            element={
              <SecuredRoute>
                <DocSearchPage />
              </SecuredRoute>
            }
          />
          <Route
            path="/view/:id"
            element={
              <SecuredRoute>
                <DocViewPage />
              </SecuredRoute>
            }
          />
          <Route
            path="/forms/add"
            element={
              <SecuredRoute>
                {userHasRole(
                  [UserRoles.MANAGER, UserRoles.ADMIN, UserRoles.BUSINESSUSER],
                  state.auth.roles as UserRoles[]
                ) ? (
                  <DocAddPage defaultValues={docEditDefaultValues} />
                ) : (
                  <Outlet />
                )}
              </SecuredRoute>
            }
          />
          <Route
            path="/forms/:id"
            element={
              <SecuredRoute>
                {userHasRole(
                  [UserRoles.MANAGER, UserRoles.ADMIN, UserRoles.BUSINESSUSER],
                  state.auth.roles as UserRoles[]
                ) ? (
                  <DocEditPage defaultValues={docEditDefaultValues} />
                ) : (
                  <Outlet />
                )}
              </SecuredRoute>
            }
          />
          <Route
            path="/forms"
            element={
              <SecuredRoute>
                {userHasRole(
                  [UserRoles.MANAGER, UserRoles.ADMIN],
                  state.auth.roles as UserRoles[]
                ) ? (
                  <FormList />
                ) : (
                  <Outlet />
                )}
              </SecuredRoute>
            }
          />
          <Route
            path="/link"
            element={
              <SecuredRoute>
                {userHasRole(
                  [UserRoles.MANAGER, UserRoles.ADMIN, UserRoles.BUSINESSUSER],
                  state.auth.roles as UserRoles[]
                ) ? (
                  <LinkLoader />
                ) : (
                  <Outlet />
                )}
              </SecuredRoute>
            }
          />
          <Route
            path="/admin/org-hierarchy"
            element={
              <SecuredRoute>
                {userHasRole([UserRoles.ADMIN], state.auth.roles as UserRoles[]) ? (
                  <OrgHierarchy />
                ) : (
                  <Outlet />
                )}
              </SecuredRoute>
            }
          />
          <Route
            path="/admin/dictionary"
            element={
              <SecuredRoute>
                {userHasRole([UserRoles.ADMIN], state.auth.roles as UserRoles[]) ? (
                  <Dictionary />
                ) : (
                  <Outlet />
                )}
              </SecuredRoute>
            }
          />
          <Route
            path="/document-api/merged-documents/file"
            element={
              <SecuredRoute>
                <DocApiDownload />
              </SecuredRoute>
            }
          />{' '}
          {/* DEPRECATED */}
          <Route
            path="/document-api/merged-documents/preview"
            element={
              <SecuredRoute>
                <DocApiPreview />
              </SecuredRoute>
            }
          />{' '}
          {/* DEPRECATED */}
          <Route
            path="/document-api/merged-documents/zip"
            element={
              <SecuredRoute>
                <DocApiZip />
              </SecuredRoute>
            }
          />
          <Route
            path="/export-metadata"
            element={
              <SecuredRoute>
                <MetadataExport />
              </SecuredRoute>
            }
          />
          <Route path="/implicit/callback" element={<CustomLoginCallback />} />{' '}
          {/* Okta automatic token processing callback endpoint */}
          <Route path="/" element={<AuthMain />} />{' '}
          {/* Used for Okta auth which is configured for the root path */}
          <Route path="/auth" element={<AuthMain />} />{' '}
          {/* Used for Azure auth which is configured for the /auth path */}
          <Route path="/auth-refresh" element={<AuthTokenRefresh />} />{' '}
          <Route path="/logout" element={<AuthLogout />} />{' '}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer
          position="top-center"
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
        />
      </Security>
    </QueryClientProvider>
  );
}

export default App;
