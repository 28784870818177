//TODO: PREVENT TYPING VALUE THAT ALREADY EXISTS NON ORG HIERARCHY

import React, { useState } from 'react';
import { Box, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import Button from '../../components/styledComponents/Button';
import { OldSelect } from '../../components/styledComponents/Select/Select';
import { useGetOrg } from '../../services/getOrg';
import { UnitType } from '../../components/DocEditContent/partials/SelectedProducts';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';
import { saveOrg } from '../../api';
import { useQueryClient } from 'react-query';
import { markDuplicateObjects } from '../../utils/helper';

type OrgGroup = {
  unit: string;
  subunit: string;
  product: string;
  lineOfBusiness: string;
  complete: boolean;
  duplicate: boolean;
  exists: boolean;
  id: string;
};

enum Operation {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

type CustomOption = {
  label: string;
  value: string;
  divider?: boolean;
  delete?: boolean;
};

const items = [
  {
    title: 'Business Unit',
    type: UnitType.UNIT,
  },
  {
    title: 'Sub Business Unit',
    type: UnitType.SUBUNIT,
  },
  {
    title: 'Program',
    type: UnitType.PRODUCT,
  },
  {
    title: 'Line of Business',
    type: UnitType.LINE_OF_BUSINESS,
  },
];

const OrgHierarchy = () => {
  const { data: orgData } = useGetOrg();
  const queryClient = useQueryClient();
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [operation, setOperation] = useState<Operation>(Operation.ADD);
  const [orgGroupList, setOrgGroupList] = useState<OrgGroup[]>([
    {
      unit: '',
      subunit: '',
      product: '',
      lineOfBusiness: '',
      complete: false,
      id: uuidv4(),
      duplicate: false,
      exists: false,
    },
  ]);
  const [userDropdownValues, setUserDropdownValues] = useState<CustomOption[][]>([[], [], [], []]);
  const [orgTextFields, setOrgTextFields] = useState<string[]>(['', '', '', '']);

  const findDuplicates = (o: OrgGroup[]) => {
    return markDuplicateObjects(['unit', 'subunit', 'product', 'lineOfBusiness'], o) as OrgGroup[];
  };

  const findExisiting = (o: OrgGroup): boolean => {
    return orgData?.shapedHierarchyOptions?.[o.unit]?.[o.subunit]?.[o.product]?.includes(
      o.lineOfBusiness
    )
      ? true
      : false;
  };

  const handleSelect = (val: string, type: UnitType, index: number) => {
    setOrgGroupList((prevState) => {
      let newOrgGroupList = _.clone(prevState);

      newOrgGroupList[index][type] = val;

      newOrgGroupList[index].complete = false;
      if (type === UnitType.UNIT) {
        newOrgGroupList[index].subunit = '';
        newOrgGroupList[index].product = '';
        newOrgGroupList[index].lineOfBusiness = '';
      } else if (type === UnitType.SUBUNIT) {
        newOrgGroupList[index].product = '';
        newOrgGroupList[index].lineOfBusiness = '';
      } else if (type === UnitType.PRODUCT) {
        newOrgGroupList[index].lineOfBusiness = '';
      } else if (val !== '') {
        newOrgGroupList[index].complete = true;
        newOrgGroupList[index].exists = findExisiting(newOrgGroupList[index]);
      }

      return findDuplicates(newOrgGroupList);
    });
  };

  const handleDropdownDelete = (e: any, option: CustomOption, index: number) => {
    //remove value from correct dropdown list and update divider
    setUserDropdownValues((prevState) => {
      let newState = _.clone(prevState);
      let indexToDelete = newState[index].findIndex((x) => x.value === option.value);
      if (indexToDelete === newState[index].length - 1 && indexToDelete !== 0)
        newState[index][newState[index].length - 2].divider = true;
      newState[index].splice(indexToDelete, 1);
      return newState;
    });
    //update orgGroup state for all orgGroups that were using the value
    orgGroupList.forEach((group, groupIndex) => {
      if (group[items[index].type] === option.value)
        handleSelect('', items[index].type, groupIndex);
    });
    //prevent the dropdown from being selected when clicking the delete icon
    e.stopPropagation();
  };

  const handleDropdownAdd = (value: string, index: number) => {
    //prevent duplicate values from being added to list
    if (userDropdownValues[index].find((opt) => opt.value === value)) return;

    if (index === 0 && orgData?.unitOptions.find((opt) => opt.value === value)) return;
    if (index === 1 && orgData?.subunitOptions.find((opt) => opt.value === value)) return;
    if (index === 2 && orgData?.productOptions.find((opt) => opt.value === value)) return;
    if (index === 3 && orgData?.lobOptions.find((opt) => opt.value === value)) return;

    setUserDropdownValues((prevState) => {
      let newState = _.clone(prevState);
      //maintain divider on last element of list
      if (newState[index][newState[index].length - 1]?.divider === true)
        newState[index][newState[index].length - 1].divider = false;
      newState[index].push({ value: value, label: value, divider: true, delete: true });
      return newState;
    });

    //clear textField after Add
    setOrgTextFields((prevState) => {
      let newState = [...prevState];
      newState[index] = '';
      return newState;
    });
  };

  const handleDelete = (index: number) => {
    setOrgGroupList((prevState) => {
      const newOrgGroupList = _.cloneDeep(prevState);
      newOrgGroupList.splice(index, 1);
      return newOrgGroupList;
    });
  };

  const handleSave = async () => {
    let listToSave;

    listToSave = orgGroupList.map((org) => ({ ...org, type: 'GROUP' }));

    await saveOrg(listToSave);
    queryClient.invalidateQueries('org');

    setOrgGroupList([]);
    setUserDropdownValues([[], [], [], []]);
  };

  return (
    <Box className="wrapper container">
      <Box className="content-box">
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box sx={{ width: '100%' }}>
              <h2 className="page-title margin-bottom-0">Add Org Hierarchy</h2>
            </Box>
            {/* <OldSelect
              key={'operation'}
              id={'operation'}
              label={'Operation'}
              value={operation}
              handleChange={(e) => {
                setOperation(e.target.value);
              }}
              options={[
                { value: Operation.ADD, label: Operation.ADD },
                { value: Operation.DELETE, label: Operation.DELETE },
              ]}
              labelSize="small"
              selectSize="small"
            /> */}
          </Stack>

          {operation === Operation.ADD && (
            <Stack>
              <Stack>
                <Stack direction={'row'} alignItems={'center'}>
                  {orgTextFields.map((text, i) => (
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      key={i}
                      sx={{ paddingY: '1rem', width: '25%' }}
                    >
                      <TextField
                        size="small"
                        placeholder={items[i].title}
                        sx={{ marginY: '1rem', width: '100%' }}
                        value={text}
                        onChange={(e) =>
                          setOrgTextFields((prevState) => {
                            let newState = [...prevState];
                            newState[i] = e.target.value;
                            return newState;
                          })
                        }
                      />
                      <Button onClick={() => handleDropdownAdd(text, i)}>Add</Button>
                    </Stack>
                  ))}
                </Stack>
                {orgGroupList.map((org, orgIndex) => {
                  return (
                    <Stack
                      direction={'row'}
                      sx={{
                        marginY: '8px',
                        padding: '1rem',
                        border: '2px solid',
                        borderRadius: 1,
                        // incomplete -> red, exisiting/duplicate orgGroup -> yellow, complete -> green
                        borderColor: !org.complete
                          ? 'red'
                          : org.exists || org.duplicate
                          ? '#ff9800'
                          : 'green',
                      }}
                      key={org.id}
                    >
                      {items.map((item, index) => {
                        let selectOptions: CustomOption[] = [];
                        //user values -> matching values -> rest of values
                        if (orgData && orgData?.shapedHierarchyOptions) {
                          const hierarhcy = orgData.shapedHierarchyOptions;
                          if (index === 0) {
                            selectOptions = orgData.unitOptions;
                          }
                          //following if statements build matching values -> rest of values and remove matching values from rest of values (remove duplicates)
                          if (index === 1 && org.unit) {
                            selectOptions = [
                              ...Object.keys(hierarhcy?.[org.unit] || {}).map(
                                (option: any, i: number, matchingArray: any) => {
                                  return {
                                    value: option,
                                    label: option,
                                    divider: i === matchingArray.length - 1,
                                  };
                                }
                              ),
                              ...orgData.subunitOptions,
                            ].filter(
                              (option: Option, index: number, thisArray: any) =>
                                index ===
                                thisArray.findIndex((o: Option) => o.label === option.label)
                            );
                          }
                          if (index === 2 && org.subunit) {
                            selectOptions = [
                              ...Object.keys(hierarhcy?.[org.unit]?.[org.subunit] || {}).map(
                                (option: any, i: number, matchingArray: any) => {
                                  return {
                                    value: option,
                                    label: option,
                                    divider: i === matchingArray.length - 1,
                                  };
                                }
                              ),
                              ...orgData.productOptions,
                            ].filter(
                              (option: Option, index: number, thisArray: any) =>
                                index ===
                                thisArray.findIndex((o: Option) => o.label === option.label)
                            );
                          }
                          if (index === 3) {
                            let lineOfBusinessArray =
                              hierarhcy?.[org.unit]?.[org.subunit]?.[org.product] ?? [];
                            selectOptions = [
                              ...lineOfBusinessArray.map(
                                (option: any, i: number, matchingArray: any) => {
                                  return {
                                    value: option,
                                    label: option,
                                    divider: i === matchingArray.length - 1,
                                  };
                                }
                              ),
                              ...orgData.lobOptions,
                            ].filter(
                              (option: Option, index: number, thisArray: any) =>
                                index ===
                                thisArray.findIndex((o: Option) => o.label === option.label)
                            );
                          }
                        }

                        //adds user values to top of the list
                        selectOptions = [...userDropdownValues[index], ...selectOptions];

                        return (
                          <OldSelect
                            key={index}
                            id={item.title}
                            label={item.title}
                            value={org[item.type] ?? ''}
                            handleChange={(e) => {
                              handleSelect(e.target.value, item.type, orgIndex);
                            }}
                            handleDelete={(e, option) => {
                              handleDropdownDelete(e, option, index);
                            }}
                            options={selectOptions ?? []}
                            labelSize="small"
                            selectSize="small"
                            disabled={index !== 0 && !org[items[index - 1].type]}
                          />
                        );
                      })}
                      <Tooltip title="Delete">
                        <IconButton
                          color="primary"
                          aria-label="delete icon"
                          component="label"
                          onClick={() => handleDelete(orgIndex)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'} sx={{ marginTop: '2rem' }}>
                <Button
                  onClick={() =>
                    setOrgGroupList((prevState) => {
                      return prevState.concat([
                        {
                          unit: '',
                          subunit: '',
                          product: '',
                          lineOfBusiness: '',
                          complete: false,
                          id: uuidv4(),
                          duplicate: false,
                          exists: false,
                        },
                      ]);
                    })
                  }
                >
                  Add Org Group
                </Button>
                <Button
                  onClick={() => handleSave()}
                  disabled={
                    orgGroupList.length === 0 ||
                    orgGroupList.some((group) => !group.complete || group.duplicate || group.exists)
                  }
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrgHierarchy;
