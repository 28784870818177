import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import 'react-day-picker/lib/style.css';
import { isEqual, cloneDeep } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { Stack } from '@mui/system';

import { formCategoryOptions, SelectedItems, formTypeOfBusiness } from './config';
import './SearchFilters.css';
import { useAppContext } from '../Store';
import {
  clearSearchFilters,
  defaultSearchFilters,
  setCheckboxFilter,
  setSearchSelectFilter,
  toggleFilters,
  setFilters,
  setIsLoading,
} from '../reducer';
import { applyFilters } from '../services';
import Switch from '../components/styledComponents/Switch';
import Button from '../components/styledComponents/Button';
import MultipleSelect from '../components/styledComponents/MultipleSelect';
import AutoCompleteMultipleSelect from '../components/styledComponents/MultipleSelect/AutoCompleteMultipleSelect';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useGetOrg } from '../services/getOrg';
import { stateCodeArray } from '../constants';

const datePickerStyles = {
  '.MuiOutlinedInput-input': {
    height: '1em',
    pt: '14px',
    pb: '15px',
  },
  'fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: '#ced4da',
  },
  ':hover fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: '#2661e3',
  },
  '.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: '#80bdff',
  },
  '& label.Mui-focused': {
    color: '#80bdff',
  },
  '.MuiInputLabel-root': {
    lineHeight: '1rem',
  },
};

const SearchFilters = () => {
  const { dispatch, state } = useAppContext();
  const { data: orgData } = useGetOrg();
  const { handleSubmit, reset } = useForm<SearchFilters>({
    defaultValues: {
      form_status: 'Active',
      is_expiring: false,
      premium_bearing: 'No',
      has_fields: false,
    },
  });

  const changeCheckbox = (
    field: SearchFilterId,
    option: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    dispatch(setCheckboxFilter(field, option, checked, dispatch, state));
  };

  const { isLoading, search } = state;

  const { areFiltersExpanded, filters, filtersAreDirty, hitsCount, searchQuery } = search;

  const showFiltersClue =
    !areFiltersExpanded && !filtersAreDirty && hitsCount === 0 && searchQuery === '' && !isLoading;

  const dispatchSetSearchSelectFilter = (id: SearchFilterId, items: SelectedItems) => {
    dispatch(setSearchSelectFilter(id, items, dispatch, state));
    dispatchApplyFilters();
  };

  const dispatchApplyFilters = async () => {
    dispatch(setIsLoading(true));
    const response = await applyFilters(state);
    dispatch(setFilters(dispatch, response));
  };

  const isDefaultFilter =
    isEqual(filters, defaultSearchFilters) ||
    isEqual(filters, { ...defaultSearchFilters, form_type_of_business: [] });

  const onSubmit: SubmitHandler<SearchFilters> = async () => {
    const newState = cloneDeep(state);
    dispatch(setIsLoading(true));
    const response = await applyFilters(newState);
    dispatch(setFilters(dispatch, response, true));
    window.scroll(0, 0);
  };

  return (
    <div>
      {!areFiltersExpanded && (
        <p className="text-align-center">
          {!isDefaultFilter && <span>Some filters have been applied.</span>}
          <span className="search-filter-label">
            <Button onClick={() => dispatch(toggleFilters(true))} variant="outlined">
              Filters
            </Button>
          </span>
        </p>
      )}
      {showFiltersClue && (
        <div className="text-align-center">
          <img
            src="/filters-clue.png"
            alt="Click to open filters"
            onClick={() => dispatch(toggleFilters(true))}
          />
        </div>
      )}
      {areFiltersExpanded && (
        <form onSubmit={handleSubmit(onSubmit)} className="content-box search-filters">
          <FormGroup>
            <Grid container spacing={{ mobile: 2, desktop: 4 }} className="grid">
              <Grid item mobile={12} tablet={6}>
                <Grid container spacing={2}>
                  <Grid item mobile={12}>
                    <AutoCompleteMultipleSelect
                      options={orgData?.hierarchyOptions ?? []}
                      selectedValue={filters.hierarchy}
                      name="hierarchy"
                      dispatchFilter={dispatchSetSearchSelectFilter}
                      selectAll
                      label="What part of the business"
                      grouped
                    />
                  </Grid>

                  <Grid item mobile={12} tablet={6}>
                    <Switch
                      checked={filters.form_status === 'Inactive'}
                      onChange={(_, val) => {
                        filters.form_status = val ? 'Inactive' : 'Active';
                        return dispatchApplyFilters();
                      }}
                      label="Inactive Forms Only"
                      controlSx={{ pb: 2 }}
                    />
                  </Grid>

                  <Grid item mobile={12} tablet={6}>
                    <Switch
                      checked={filters.is_expiring}
                      onChange={(_, val) => {
                        filters.is_expiring = val;
                        return dispatchApplyFilters();
                      }}
                      label="Form expiring in 90 days?"
                      controlSx={{ pb: 2 }}
                    />
                  </Grid>

                  <Grid item mobile={12}>
                    <MultipleSelect
                      label="Type of insurance (LOB)"
                      options={orgData?.lobOptions ?? []}
                      selectedValue={filters.line_of_business}
                      name="line_of_business"
                      dispatchFilter={dispatchSetSearchSelectFilter}
                    />
                  </Grid>

                  <Grid item mobile={12} desktop={6}>
                    <MultipleSelect
                      label="Approved for use in:"
                      options={stateCodeArray.map((x) => ({ label: x.label, value: x.key }))}
                      name="state_filed"
                      selectedValue={filters.state_filed}
                      dispatchFilter={dispatchSetSearchSelectFilter}
                    />
                  </Grid>

                  <Grid item mobile={12} desktop={6}>
                    <MultipleSelect
                      label="Carrier"
                      options={
                        orgData?.carrierOptions.map((c) => {
                          return { label: c.value, value: c.value };
                        }) ?? []
                      }
                      name="carrier"
                      selectedValue={filters.carrier}
                      dispatchFilter={dispatchSetSearchSelectFilter}
                    />
                  </Grid>
                  <Grid item mobile={12} desktop={6}>
                    <MultipleSelect
                      label="Form source"
                      options={orgData?.formSourceOptions ?? []}
                      name="form_source"
                      selectedValue={filters.form_source}
                      dispatchFilter={dispatchSetSearchSelectFilter}
                    />
                  </Grid>

                  <Grid item mobile={12} desktop={6}>
                    <MultipleSelect
                      label="Form type"
                      options={orgData?.formTypeOptions ?? []}
                      name="form_type"
                      selectedValue={filters.form_type}
                      dispatchFilter={dispatchSetSearchSelectFilter}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mobile={12} tablet={6}>
                <Grid container spacing={2}>
                  <Grid item mobile={12}>
                    <InputLabel
                      sx={{
                        lineHeight: '1.375rem',
                        fontWeight: 600,
                        color: '#041c2c',
                        transform: 'initial',
                        fontSize: 16,
                      }}
                      shrink
                      htmlFor="customMultipleSelect"
                    >
                      Effective Date
                    </InputLabel>
                    <Stack direction="row" spacing={2} sx={{ mt: '6px' }}>
                      <DatePicker
                        label="From"
                        value={
                          filters.state_effective_date_min
                            ? moment(filters.state_effective_date_min)
                            : null
                        }
                        maxDate={
                          filters.state_effective_date_max &&
                          moment(filters.state_effective_date_max).subtract(1, 'd')
                        }
                        onChange={(newValue) => {
                          filters.state_effective_date_min = newValue;
                          dispatchApplyFilters();
                        }}
                        sx={datePickerStyles}
                      />

                      <DatePicker
                        label="To"
                        value={
                          filters.state_effective_date_max
                            ? moment(filters.state_effective_date_max)
                            : null
                        }
                        minDate={
                          filters.state_effective_date_min &&
                          moment(filters.state_effective_date_min).add(1, 'd')
                        }
                        onChange={(newValue) => {
                          filters.state_effective_date_max = newValue;
                          dispatchApplyFilters();
                        }}
                        sx={datePickerStyles}
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12}>
                    <InputLabel
                      sx={{
                        lineHeight: '1.375rem',
                        fontWeight: 600,
                        color: '#041c2c',
                        transform: 'initial',
                        fontSize: 16,
                      }}
                      shrink
                      htmlFor="customMultipleSelect"
                    >
                      Expiration Date
                    </InputLabel>
                    <Stack direction="row" spacing={2} sx={{ mt: '6px' }}>
                      <DatePicker
                        label="From"
                        value={
                          filters.state_expired_date_min
                            ? moment(filters.state_expired_date_min)
                            : null
                        }
                        maxDate={
                          filters.state_expired_date_max &&
                          moment(filters.state_expired_date_max).subtract(1, 'd')
                        }
                        onChange={(newValue) => {
                          console.log(newValue);
                          filters.state_expired_date_min = newValue;
                          dispatchApplyFilters();
                        }}
                        sx={datePickerStyles}
                      />
                      <DatePicker
                        label="To"
                        value={
                          filters.state_expired_date_max
                            ? moment(filters.state_expired_date_max)
                            : null
                        }
                        minDate={
                          filters.state_expired_date_min &&
                          moment(filters.state_expired_date_min).add(1, 'd')
                        }
                        onChange={(newValue) => {
                          filters.state_expired_date_max = newValue;
                          dispatchApplyFilters();
                        }}
                        sx={datePickerStyles}
                      />
                    </Stack>
                  </Grid>
                  <Grid item mobile={12} tablet={6}>
                    <label className="form-label">Form language</label>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="b"
                            name="brc"
                            checked={filters.impact_on_coverage.has('Broadens Coverage')}
                            onChange={(e: any) =>
                              changeCheckbox('impact_on_coverage', 'Broadens Coverage', e)
                            }
                          />
                        }
                        label="Broadens"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="r"
                            name="brc"
                            checked={filters.impact_on_coverage.has('Restricts Coverage')}
                            onChange={(e: any) =>
                              changeCheckbox('impact_on_coverage', 'Restricts Coverage', e)
                            }
                          />
                        }
                        label="Restricts"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="c"
                            name="brc"
                            checked={filters.impact_on_coverage.has('Clarifies Coverage')}
                            onChange={(e: any) =>
                              changeCheckbox('impact_on_coverage', 'Clarifies Coverage', e)
                            }
                          />
                        }
                        label="Clarifies"
                      />
                    </div>
                  </Grid>
                  <Grid item mobile={12} tablet={6}>
                    <label className="form-label">Form is</label>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="m"
                            name="mco"
                            checked={filters.form_usage_category.has('Mandatory')}
                            onChange={(e: any) =>
                              changeCheckbox('form_usage_category', 'Mandatory', e)
                            }
                          />
                        }
                        label="Mandatory"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="co"
                            name="mco"
                            checked={filters.form_usage_category.has('Conditional')}
                            onChange={(e: any) =>
                              changeCheckbox('form_usage_category', 'Conditional', e)
                            }
                          />
                        }
                        label="Conditional"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="o"
                            name="mco"
                            checked={filters.form_usage_category.has('Optional')}
                            onChange={(e: any) =>
                              changeCheckbox('form_usage_category', 'Optional', e)
                            }
                          />
                        }
                        label="Optional"
                      />
                    </div>
                  </Grid>
                  <Grid item mobile={12} tablet={6}>
                    <Switch
                      checked={filters.premium_bearing === 'Yes'}
                      onChange={(_, val) => {
                        filters.premium_bearing = val ? 'Yes' : 'No';
                        return dispatchApplyFilters();
                      }}
                      label={
                        filters.premium_bearing === 'Yes'
                          ? 'Premium bearing only'
                          : 'Premium bearing or not'
                      }
                    />
                  </Grid>
                  <Grid item mobile={12} tablet={6}>
                    <Switch
                      checked={filters.has_fields}
                      onChange={(_, val) => {
                        filters.has_fields = val;
                        return dispatchApplyFilters();
                      }}
                      label={
                        filters.has_fields ? 'Form contains fields' : 'Form contains fields or not'
                      }
                    />
                  </Grid>
                  <Grid item mobile={12} desktop={6}>
                    <MultipleSelect
                      label="Form type of business"
                      name="form_type_of_business"
                      options={formTypeOfBusiness}
                      dispatchFilter={dispatchSetSearchSelectFilter}
                      selectedValue={filters.form_type_of_business}
                    />
                  </Grid>
                  <Grid item mobile={12} desktop={6}>
                    <MultipleSelect
                      label="Form category"
                      name="form_category"
                      options={formCategoryOptions}
                      dispatchFilter={dispatchSetSearchSelectFilter}
                      selectedValue={filters.form_category}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mobile={6}>
                <div className="text-align-right">
                  <Button isLoading={isLoading} type="submit">
                    Apply filters
                  </Button>
                </div>
              </Grid>

              <Grid item mobile={6}>
                <div>
                  <Button
                    onClick={() => {
                      reset();
                      dispatch(clearSearchFilters(state));
                    }}
                    variant="outlined"
                  >
                    Clear filters
                  </Button>
                </div>
              </Grid>
            </Grid>

            <IconButton
              size="small"
              aria-label="close"
              onClick={() => {
                dispatch(toggleFilters(false));
              }}
              sx={{
                height: '34px',
                alignSelf: 'center',
                position: 'absolute',
                top: '3px',
                right: '3px',
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </FormGroup>
        </form>
      )}
    </div>
  );
};

export default SearchFilters;
