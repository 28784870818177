import { useNavigate } from 'react-router-dom';
import { oktaConfig } from '../auth/AuthConfig';
import { useAppContext } from '../Store';
import { useOktaAuth } from '@okta/okta-react';
import { azureSSO } from '../auth/AzureSSO';

const useForceLogout = () => {
  const navigate = useNavigate();
  const { state } = useAppContext();
  const { oktaAuth } = useOktaAuth();

  const handleForceLogout = async (error: any) => {
    if (error?.message === 'Force Logout') {
      if (state.auth.provider === 'Okta') {
        oktaAuth.signOut({
          postLogoutRedirectUri: oktaConfig.postLogoutRedirectUri,
        });
      } else {
        await azureSSO.initialize();
        azureSSO.msalInstance.logoutRedirect();
      }
      navigate('/');
    }
  };

  return handleForceLogout;
};

export default useForceLogout;
