import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useRef, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import EditIcon from '@mui/icons-material/Edit';

import './DocumentResultCard.css';
import { Link } from 'react-router-dom';
import { Tooltipify } from '../components/Tooltipify';
import { carriers } from '../constants';
import { useAppContext } from '../Store';
import { RESET_VIEW_DOC, SET_PREVIOUS_VIEWDOC_ID } from '../reducer';

var HtmlToReactParser = require('html-to-react').Parser;

type PropsType = { doc: any; isEditor: boolean; headerHeight: number; disableCardAnchor: boolean };
type CarrierLabels = { label: string; alt: string };

const highlightedBorderStyle = {
  border: '2px solid',
  borderRadius: '8px',
  borderColor: 'green',
};

const DocumentResultCard = ({ doc, isEditor, headerHeight, disableCardAnchor }: PropsType) => {
  const { state, dispatch } = useAppContext();
  const { highlight, _source } = doc;
  const toView = `/view/${doc._id}`;
  let highlightElement;
  let carriersDistinct = new Set([]);
  let carrierLabels: CarrierLabels[] = [];
  const ref = useRef<null | HTMLDivElement>(null);
  const [highlightCard, setHighlightCard] = useState(false);

  useEffect(() => {
    if (disableCardAnchor) {
      dispatch({ type: SET_PREVIOUS_VIEWDOC_ID, payload: '' });
      setHighlightCard(false);
    } else if (state.previousViewDocId === ref.current?.id) {
      const y = ref.current?.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setHighlightCard(true);
    }
  }, [headerHeight, state.previousViewDocId, disableCardAnchor, dispatch]);

  if (!_source) return null;

  try {
    let highlights =
      highlight && highlight['attachment.content'] && highlight['attachment.content'].join('<br/>');

    if (!highlights) {
      highlights = _source.attachment && _source.attachment.content;
    }

    const cleanHighlights = sanitizeHtml(highlights.replace(/(\r\n|\n|\r)/gm, ''), {
      allowedTags: ['strong', 'br'],
    });
    const htmlToReactParser = new HtmlToReactParser();

    highlightElement = htmlToReactParser.parse(cleanHighlights);
  } catch (error) {
    console.error(error);
  }

  if (_source.state_filings && _source.state_filings.length > 0) {
    carriersDistinct = new Set(_source.state_filings.map((item: any) => item.carrier));
    carrierLabels = Array.from(carriersDistinct)
      .filter(Boolean)
      .map((item: string) => ({
        label: item,
        alt: carriers.get(item) || '',
      }));
  }

  return (
    <Box
      className="listing-card-row-seperator"
      sx={{ display: 'flex' } && highlightCard ? highlightedBorderStyle : {}}
      ref={ref}
      id={doc._id}
    >
      <Link
        className="nostyle listing-card-row margin-bottom-0"
        to={toView}
        style={{ width: '100%' }}
        onClick={() => dispatch({ type: RESET_VIEW_DOC })}
      >
        <ButtonGroup sx={{ width: '100%' }}>
          <div className={`cell cell--percent-20`}>
            <Tooltip title={_source.form_status === 'Active' ? 'Active' : 'Inactive'}>
              <span
                className="tag--primary"
                style={{
                  background: _source.form_status === 'Active' ? 'green' : 'red',
                }}
              ></span>
            </Tooltip>
            <p>
              <strong>{_source.form_number}</strong>
              <br />
              {_source.form_title}
            </p>
          </div>
          <div className="cell cell--percent-60 positioning">
            <div className="subcell">
              <Typography className="cell--percent-25">{_source.bu}</Typography>
              <Typography className="cell--percent-25">{_source.opu}</Typography>
              <Typography className="cell--percent-25">{_source.program_or_product}</Typography>
              <Typography className="cell--percent-25">{_source.line_of_business}</Typography>
            </div>
            {highlightElement ? (
              <Tooltipify text={highlightElement} className="content"></Tooltipify>
            ) : null}
          </div>
          <div className="cell cell--percent-10">
            <Typography>{_source.form_type}</Typography>
          </div>
          <div className="cell cell--percent-10">
            <Typography>{_source.form_type_of_business}</Typography>
            {carrierLabels.map((carrierLabel, idx) => (
              <Tooltip
                title={carrierLabel.alt}
                key={`${carrierLabel.label}-${idx}`}
                className="inline-labels"
              >
                <Typography sx={{ fontSize: 14 }}>
                  {idx < carrierLabels.length - 1 ? `${carrierLabel.label}, ` : carrierLabel.label}
                </Typography>
              </Tooltip>
            ))}
          </div>
        </ButtonGroup>
      </Link>
      {isEditor && (
        <Box component="span" className="tag--actions">
          <Tooltip title={`Edit ${_source.form_number ?? 'form'}`}>
            <Link
              to={`/forms/${doc._id}`}
              onClick={() => dispatch({ type: SET_PREVIOUS_VIEWDOC_ID, payload: doc._id })}
            >
              <IconButton
                size="small"
                aria-label="edit"
                sx={{ height: '34px', alignSelf: 'center' }}
              >
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default DocumentResultCard;
