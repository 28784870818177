import { useInfiniteQuery } from 'react-query';
import { LAMBDA_FORMS } from './constants';
import authenticatedApi from './authenticatedApi';
import { Form } from '../pages/FormList/FormList';

export const getForms = async (
  lastEvaluatedKey = null,
  userEmail?: string,
  excludeEmail?: boolean
): Promise<{ forms: Form[]; lastEvaluatedKey: any }> => {
  const params = {
    lastEvaluatedKey: lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : undefined,
    userEmail,
    excludeEmail,
  };

  const queryString = (Object.keys(params) as ('lastEvaluatedKey' | 'userEmail' | 'excludeEmail')[])
    .map((key) =>
      params[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(params?.[key] || '')}` : ''
    )
    .filter(Boolean)
    .join('&');

  const result: any = await authenticatedApi.get(`${LAMBDA_FORMS}/forms?${queryString}`);

  const forms: Form[] = result.data.map((x: any) => {
    const form: Form = {
      formNumber: x.metadata.form_number,
      formTitle: x.metadata.form_title,
      status: x.status,
      dateModified: x.timestamp,
      id: x.id,
      user: x.user,
      userEmail: x.user.email,
    };
    return form;
  });

  return {
    forms,
    lastEvaluatedKey: result.lastEvaluatedKey,
  };
};

export const useGetForms = (userEmail?: string, excludeEmail?: boolean) => {
  return useInfiniteQuery(
    ['forms', userEmail, excludeEmail],
    ({ pageParam = null }) => getForms(pageParam, userEmail, excludeEmail),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.lastEvaluatedKey || false;
      },
      cacheTime: 60000, // Example: Cache for 1 minute
      staleTime: 30000,
    }
  );
};
