import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { azureSSO, AuthenticatedTemplate, UnauthenticatedTemplate } from './AzureSSO';
import { clearUser, setUser, updateAuth } from '../reducer';
import { useAppContext } from '../Store';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';

function AuthMain() {
  const { authState, oktaAuth } = useOktaAuth();
  const { state, dispatch } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [redirectUri, setRedirectUri] = useState('/search');

  const login = async () => {
    if (location.pathname !== '/auth') {
      navigate('/auth');
    }
    const { auth } = state;
    auth.isAuthenticating = true;
    dispatch(updateAuth(auth));
    try {
      await azureSSO.login();
    } catch (error) {
      console.error('[Azure] retryLogin or login error', error);
    }
  };
  const loginWithOkta = () => {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    const retryLogin = async () => {
      try {
        const response: any = await azureSSO.tryReLogin(state.auth);
        if (response) {
          const { authState, authenticationResult } = response;
          dispatch(updateAuth(authState));
          dispatch(
            setUser(
              authenticationResult.account?.name || '',
              authState.accessToken || '',
              'Azure',
              authState.roles,
              authenticationResult.account?.username || ''
            )
          );
        }
      } catch (error) {
        console.log(error);
        dispatch(clearUser());
      }
    };

    if (state.auth.isAuthenticating && state.auth.provider === 'Azure') {
      retryLogin();
    }

    if (state.auth.redirectUri) {
      setRedirectUri(state.auth.redirectUri);
    }
  }, [state, dispatch, state.auth]);

  useEffect(() => {
    azureSSO.msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const roles = azureSSO.getRoles(payload);
        dispatch(
          setUser(
            payload.account?.name || '',
            payload.idToken || '',
            'Azure',
            roles,
            payload.account?.username || ''
          )
        );
      }
    });
  }, [dispatch]);

  if (authState?.isPending || state.auth.isAuthenticating) {
    return (
      <div className="wrapper container external">
        <div className="content-box content-box-600">
          <h3>Checking authentication</h3>
          <p>Please wait...</p>
        </div>
      </div>
    );
  } else if (
    ((!authState?.isAuthenticated || authState === null) && state.auth.provider !== 'Azure') ||
    !state.auth.isAuthenticated
  ) {
    return (
      <div className="wrapper container external">
        <div className="content-box content-box-600">
          <h3>Authentication is required</h3>
          <p>Please sign in using one of the following providers</p>
          <p>
            <UnauthenticatedTemplate>
              <Button variant="contained" onClick={login}>
                Login with Azure SSO &nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>Authenticated!</AuthenticatedTemplate>
            &nbsp;
            <Button variant="contained" onClick={loginWithOkta}>
              Login with Okta &nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faSignInAlt} />
            </Button>
          </p>
          <p>Note: "Login with Okta" is the recommended method of authentication for Paper and will be the only available option soon.</p>
        </div>
      </div>
    );
  }

  return <Navigate to={redirectUri} replace />;
}

export default AuthMain;
