import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Box,
  Checkbox,
  ListItemIcon,
  ListItemText,
  InputLabel,
  MenuItem,
  FormControl,
  InputBase,
  Divider,
  Typography,
  ListSubheader,
} from '@mui/material';
import { styled } from '@mui/styles';
import { SelectedItems } from '../../../doc-search/config';
import { filterByCategory } from './helper';

const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3.5),
  },
  '& .MuiInputBase-input': {
    maxHeight: 80,
    overflowY: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

function getStyles(name: string, value: string[], theme: Theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type Option = {
  value: string;
  label: string;
};

interface MultipleSelectProps {
  options: Option[];
  selectedValue: string[];
  dispatchFilter: (id: SearchFilterId, items: SelectedItems) => void;
  name: SearchFilterId;
  selectAll?: boolean;
  label: string;
  compact?: boolean;
}
const group = (options: any) => {
  const arr: any[] = [];

  options.map((option: any) => {
    const last = option.label.split('>');
    const label = last.slice(1).join('>');
    const found = arr.find((a) => a.category === last[0].trim());

    if (!found) {
      arr.push({ category: last[0].trim(), list: [{ label, value: option.value }] });
    } else {
      found.list.push({ label, value: option.value });
    }

    return option;
  });

  return arr;
};

export default function MultipleSelect({
  options,
  selectedValue,
  label,
  name,
  dispatchFilter,
  compact = true,
}: MultipleSelectProps) {
  const compactStyle = compact
    ? { '& .MuiCheckbox-root': { p: 0, mx: '9px' }, fontSize: 12, minHeight: 24, height: 24 }
    : {};

  const theme = useTheme();
  const SELECT_ALL = 'SELECT ALL';
  const filteredOptions = options.filter(({ value, label }) => (value || label) && value !== 'ALL');
  const isAllSelected =
    filteredOptions.length > 0 && selectedValue.length === filteredOptions.length;

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    const values = event.target.value as typeof selectedValue;

    if (values[values.length - 1] === SELECT_ALL) {
      const selected = selectedValue.length === filteredOptions.length ? [] : filteredOptions;
      dispatchFilter(name, selected as unknown as SelectedItems);
      return;
    }

    const found = filteredOptions.find(
      (option) => values[values.length - 1] === option.value
    ) as any;

    const foundSelected = selectedValue.find((val: any) => val.value === found.value);

    if (foundSelected) {
      dispatchFilter(
        name,
        [...selectedValue].filter(
          (val: any) => val.value !== found.value
        ) as unknown as SelectedItems
      );
    } else {
      dispatchFilter(name, [...selectedValue, found] as unknown as SelectedItems);
    }
  };

  const handleGroupChange = (values: any) => {
    const found = filteredOptions.find(
      (option) => values[values.length - 1]?.value === option.value
    ) as any;

    const foundSelected = selectedValue.find((val: any) => val.value === found.value);

    if (foundSelected) {
      dispatchFilter(
        name,
        [...selectedValue].filter(
          (val: any) => val.value !== found.value
        ) as unknown as SelectedItems
      );
    } else {
      dispatchFilter(name, [...selectedValue, found] as unknown as SelectedItems);
    }
  };

  const handleGroupSelectAllChange = (values: any, list: any, category: string) => {
    let valuesToDispatch = [];
    const filtered = filterByCategory(values, category);
    const cleanFiltered = filterByCategory(values, category, true);

    if (filtered.length === list.length) {
      valuesToDispatch = cleanFiltered;
    } else {
      valuesToDispatch = [...cleanFiltered, ...list];
    }

    dispatchFilter(name, valuesToDispatch as unknown as SelectedItems);
  };

  const displayNormalOptions = () =>
    filteredOptions.map((option) => (
      <MenuItem
        key={`${name}-${option.value}`}
        value={option.value}
        style={getStyles(option.value, selectedValue, theme)}
        sx={compactStyle}
      >
        <Checkbox
          checked={selectedValue.map((value: any) => value.value).indexOf(option.value) > -1}
        />
        <ListItemText primary={option.label} />
      </MenuItem>
    ));

  const isAllCategorySelected = (list: any, category: string) => {
    if (list.length > 0) {
      const filtered = filterByCategory(selectedValue, category);
      const filteredList = filterByCategory(list, category);

      return filtered.length === filteredList.length;
    }

    return false;
  };

  const displayGroupedOptions = () =>
    group(filteredOptions).map((option) => (
      <Box key={`${name}-${option.category}`}>
        <ListSubheader>{option.category}</ListSubheader>
        <MenuItem
          value={`${SELECT_ALL} ${option.category}`}
          sx={compactStyle}
          onClick={() => handleGroupSelectAllChange(selectedValue, option.list, option.category)}
        >
          <ListItemIcon>
            <Checkbox
              checked={isAllCategorySelected(option.list, option.category)}
              indeterminate={isAllCategorySelected(option.list, option.category)}
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography sx={{ fontWeight: 700 }}>
              {SELECT_ALL} {option.category}
            </Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        {option.list.map((item: any) => (
          <MenuItem
            onClick={() => handleGroupChange(selectedValue.concat(item))}
            key={`${name}-${item.value}`}
            value={item.value}
            style={getStyles(item.value, selectedValue, theme)}
            sx={compactStyle}
          >
            <Checkbox
              checked={selectedValue.map((value: any) => value.value).indexOf(item.value) > -1}
            />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Box>
    ));

  return (
    <Box>
      <FormControl sx={{ width: 1 }} variant="standard">
        <InputLabel
          sx={{
            lineHeight: '1.375rem',
            fontWeight: 600,
            mb: '0.25rem',
            color: '#041c2c',
            transform: 'initial',
            fontSize: 16,
          }}
          shrink
          htmlFor="customMultipleSelect"
        >
          {label}
        </InputLabel>
        <Select
          id="customMultipleSelect"
          multiple
          value={selectedValue}
          onChange={handleChange}
          MenuProps={MenuProps}
          input={<CustomInput />}
          renderValue={(selected) => selected.map(({ label }: any) => label).join('; ')}
        >
          <MenuItem value={SELECT_ALL} sx={compactStyle}>
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selectedValue.length > 0 && selectedValue.length < filteredOptions.length
                }
              />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography sx={{ fontWeight: 700 }}>{SELECT_ALL}</Typography>
            </ListItemText>
          </MenuItem>
          <Divider />
          {name === 'hierarchy' ? displayGroupedOptions() : displayNormalOptions()}
        </Select>
      </FormControl>
    </Box>
  );
}
