import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import _ from 'lodash';
import { deleteRule, fetchRanges, fetchRules, saveRanges, saveRules } from '../../../api';
import {
  disableForm,
  markDuplicateObjects,
  toFormRule,
  userHasAccess,
} from '../../../utils/helper';
import { accessPolicy, UserRoles } from '../../../constants';
import { OldSelect } from '../../styledComponents/Select/Select';
import Button from '../../styledComponents/Button';
import ApplicableStates from './ApplicableStates';
import { useAppContext } from '../../../Store';
import { useDocEditFileQuery } from '../../../services/getDocEditFile';
import { useRulesQuery } from '../../../services/getRules';
import { useGetOrg } from '../../../services/getOrg';
import LoadingOverlay from 'react-loading-overlay-ts';
import { v4 as uuidv4 } from 'uuid';

export enum UnitType {
  UNIT = 'unit',
  SUBUNIT = 'subunit',
  PRODUCT = 'product',
  LINE_OF_BUSINESS = 'lineOfBusiness',
}

export enum OtherType {
  BUSINESS_TYPE = 'businessType',
  IS_ACTIVE = 'isActive',
  COPY = 'copy',
  DELETE = 'delete',
}

export type Product = {
  unit: string;
  subunit: string;
  product: string;
  lineOfBusiness: string;
  businessType: boolean;
  rand: string;
  isActive: boolean;
  id: string;
  complete: boolean;
  duplicate: boolean;
};

interface SingleProductProps {
  productIndex: number;
  unit: string;
  subunit: string;
  product: string;
  lineOfBusiness: string;
  indexId: string;
  isActive: boolean;
  businessType: boolean;
  complete: boolean;
  duplicate: boolean;
  formId: string;
  productHandler: any;
  disabled: boolean;
  userRoles: UserRoles[];
  rand: string;
}

const SingleProduct = memo((props: SingleProductProps) => {
  const { productIndex, indexId, formId, productHandler, disabled, userRoles, ...rest } = props;

  const thisProduct: Product = {
    ...rest,
    ...{ id: indexId },
  };

  const { data: orgData, isLoading: isOrgDataLoading } = useGetOrg();

  const items = [
    {
      title: 'Business Unit',
      type: UnitType.UNIT,
    },
    {
      title: 'Sub Business Unit',
      type: UnitType.SUBUNIT,
    },
    {
      title: 'Program',
      type: UnitType.PRODUCT,
    },
    {
      title: 'Line of Business',
      type: UnitType.LINE_OF_BUSINESS,
    },
  ];

  return (
    <>
      {!isOrgDataLoading && (
        <Box
          sx={{
            border: '2px solid',
            borderRadius: 1,
            borderColor: thisProduct.duplicate
              ? '#ff9800'
              : !thisProduct.complete
              ? 'red'
              : 'green',
            px: 1,
            pb: 1,
            pt: 2,
            mx: 'auto',
            my: 1,
            width: '100%',
          }}
        >
          <Stack direction="row" spacing={2}>
            {items.map((item, index) => {
              const selectValue = thisProduct[item.type];
              let selectOptions = [];

              if (orgData && orgData?.shapedHierarchyOptions) {
                const hierarhcy = orgData.shapedHierarchyOptions;
                if (index === 0) {
                  selectOptions = Object.keys(hierarhcy);
                }
                if (index === 1 && thisProduct.unit) {
                  selectOptions = Object.keys(hierarhcy?.[thisProduct.unit] || {});
                }
                if (index === 2 && thisProduct.subunit) {
                  selectOptions = Object.keys(
                    hierarhcy?.[thisProduct.unit]?.[thisProduct.subunit] || {}
                  );
                }
                if (index === 3 && thisProduct.product) {
                  selectOptions =
                    hierarhcy?.[thisProduct.unit]?.[thisProduct.subunit]?.[thisProduct.product] ||
                    [];
                }
              }

              selectOptions.sort((a: string, b: string) =>
                a.toUpperCase() < b.toUpperCase() ? -1 : 1
              );

              return (
                <OldSelect
                  key={index}
                  id={item.title}
                  label={item.title}
                  value={selectValue || ''}
                  handleChange={(e) => {
                    productHandler(e, item.type, productIndex);
                  }}
                  options={
                    selectOptions.length > 0
                      ? selectOptions.map((option: any) => {
                          return { value: option, label: option };
                        })
                      : [{ value: selectValue, label: selectValue }]
                  }
                  labelSize="small"
                  selectSize="small"
                  disabled={disabled || (index !== 0 && !thisProduct[items[index - 1].type])}
                />
              );
            })}
            <Stack direction="row">
              <Tooltip title="Delete">
                <IconButton
                  color="primary"
                  aria-label="delete icon"
                  component="label"
                  disabled={disabled || !thisProduct.complete}
                  onClick={() => productHandler('', OtherType.DELETE, productIndex, thisProduct)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Make a copy of this product">
                <IconButton
                  color="primary"
                  aria-label="copy icon"
                  component="label"
                  disabled={disabled || !thisProduct.complete}
                  onClick={() =>
                    productHandler('', OtherType.COPY, productIndex, {
                      ...thisProduct,
                      ...{ id: '', rand: uuidv4(), duplicate: true },
                    })
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ padding: '8px 16px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={thisProduct.businessType}
                  onChange={(e) => {
                    productHandler(e, OtherType.BUSINESS_TYPE, productIndex);
                  }}
                  disabled={disabled}
                  sx={{ padding: '8px' }}
                />
              }
              label="Admitted"
            />
            <FormControlLabel
              disabled={disabled}
              labelPlacement="end"
              sx={{ alignSelf: 'flex-start' }}
              control={
                <Switch
                  checked={thisProduct.isActive}
                  onChange={(e) => {
                    productHandler(e, OtherType.IS_ACTIVE, productIndex);
                  }}
                />
              }
              label={thisProduct.isActive ? 'Active' : 'Inactive'}
            />
          </Stack>
          {userHasAccess(accessPolicy.applicableStates.read, userRoles) && (
            <ApplicableStates
              formId={formId}
              indexId={thisProduct.id}
              index={productIndex}
              complete={thisProduct.complete}
            />
          )}
        </Box>
      )}
    </>
  );
});

const emptyProduct: Product = {
  unit: '',
  subunit: '',
  product: '',
  lineOfBusiness: '',
  businessType: true,
  rand: 'tempId',
  isActive: true,
  id: '',
  complete: false,
  duplicate: false,
};

type SelectedProductsProps = {
  formId: string;
  setIsProductsComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isProductsComplete: boolean;
};

const SelectedProducts: React.FC<SelectedProductsProps> = (props) => {
  const { state } = useAppContext();
  const { formId, setIsProductsComplete, isProductsComplete } = props;
  const { data: docEditFileData } = useDocEditFileQuery(formId);
  const { data: rulesData, isLoading: isRulesLoading } = useRulesQuery(formId);
  const [productList, setProductList] = useState<Product[]>([]);
  const [copyIsLoading, setCopyIsLoading] = useState<boolean>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const userRoles = state.auth.roles as UserRoles[];

  const productRef = useRef<null | HTMLDivElement>(null);

  const disabled = disableForm(docEditFileData?.data, state.auth);

  const saveProductOnChange = useCallback(
    async (productToSave: Product, index: number, statesData?: any) => {
      const formRule = toFormRule(index, productToSave);
      await saveRules([formRule], formId);
      if (statesData) {
        await saveRanges(formId, index, statesData).catch((err) => console.log(err));
      }
    },
    [formId]
  );

  const findDuplicates = (p: Product[]) => {
    return markDuplicateObjects(
      ['unit', 'subunit', 'product', 'lineOfBusiness', 'businessType'],
      p
    ) as Product[];
  };

  const handleSelect = useCallback(
    (e: any, type: UnitType, index: number) => {
      setProductList((prevProductList) => {
        let newProductList = _.cloneDeep(prevProductList);

        newProductList[index][type] = e.target.value;

        newProductList[index].complete = false;
        if (type === UnitType.UNIT) {
          newProductList[index].subunit = '';
          newProductList[index].product = '';
          newProductList[index].lineOfBusiness = '';
        } else if (type === UnitType.SUBUNIT) {
          newProductList[index].product = '';
          newProductList[index].lineOfBusiness = '';
        } else if (type === UnitType.PRODUCT) {
          newProductList[index].lineOfBusiness = '';
        } else {
          newProductList[index].complete = true;
        }

        if (newProductList[index].complete) saveProductOnChange(newProductList[index], index);

        let newProductListWithDuplicatesMarked = findDuplicates(newProductList);

        return newProductListWithDuplicatesMarked;
      });
    },
    [saveProductOnChange]
  );

  const handleCheckbox = useCallback(
    (e: any, index: number) => {
      setProductList((prevProductList) => {
        let newProductList = prevProductList.map((product, i) => {
          if (i !== index) return product;
          else {
            let newProduct = _.clone(product);
            newProduct.businessType = e.target.checked;
            if (newProduct.complete) saveProductOnChange(newProduct, index);
            return newProduct;
          }
        });
        let newProductListWithDuplicatesMarked = findDuplicates(newProductList);

        return newProductListWithDuplicatesMarked;
      });
    },
    [saveProductOnChange]
  );

  const handleToggle = useCallback(
    (e: any, index: number) => {
      setProductList((prevProductList) =>
        prevProductList.map((product, i) => {
          if (i !== index) return product;
          else {
            let newProduct = _.clone(product);
            newProduct.isActive = !newProduct.isActive;
            if (newProduct.complete) saveProductOnChange(newProduct, index);
            return newProduct;
          }
        })
      );
    },
    [saveProductOnChange]
  );

  const handleCopy = useCallback(
    async (index: number, copy: Product) => {
      setCopyIsLoading(true);
      //save the state data to the new product in a temp field
      const applicableStates = await fetchRanges(formId, index);
      let data: any = [];
      if (applicableStates.data.ranges) data = Object.values(applicableStates.data.ranges);

      // this call to rules is a pain point.  Need to get the length of the product list at the time of copy in a way which
      // doesn't rerender every product
      const rules = await fetchRules(formId);

      await saveProductOnChange(copy, rules.data.length, data.length > 0 ? data : undefined);

      setProductList((prevState) => {
        let newProductList = _.cloneDeep(prevState);

        newProductList[index].duplicate = true;

        // insert copy before the last product if that product was newly created and incomplete
        // this prevents the need to disable all copy buttons when one is in progress
        if (newProductList[newProductList.length - 1].unit === '') {
          newProductList.splice(newProductList.length - 1, 0, copy);
          return newProductList;
        } else return newProductList.concat([copy]);
      });

      setTimeout(() => scrollToLastProduct(), 1000);
      setCopyIsLoading(false);
    },
    [saveProductOnChange, formId]
  );

  const handleDelete = useCallback(
    async (index: number, product: Product) => {
      setProductList((prevState) => {
        const newProductList = _.cloneDeep(prevState);
        newProductList.splice(index, 1);
        let newProductListWithDuplicatesMarked = findDuplicates(newProductList);
        return newProductListWithDuplicatesMarked;
      });

      // deletes from the top of the list are the most expensive as they rerender every product below.
      // every product belows prop "productIndex" changes because all of the products below shift their index by 1
      if (product.complete) {
        setDeleteIsLoading(true);
        await deleteRule(formId ?? '', index);
        setDeleteIsLoading(false);
      }
    },
    [formId]
  );

  const productHandler = useCallback(
    (e: any, type: UnitType | OtherType, index: number, product?: Product) => {
      if (type === OtherType.BUSINESS_TYPE) {
        handleCheckbox(e, index);
      } else if (type === OtherType.IS_ACTIVE) {
        handleToggle(e, index);
      } else if (type === OtherType.COPY && product) {
        handleCopy(index, product);
      } else if (type === OtherType.DELETE && product) {
        handleDelete(index, product);
      } else handleSelect(e, type as UnitType, index);
    },
    [handleCheckbox, handleSelect, handleToggle, handleCopy, handleDelete]
  );

  useEffect(() => {
    if (productList.length === 0) {
      setIsProductsComplete(false);
    } else if (productList.length > 0 && productList.some((product) => !product.complete)) {
      setIsProductsComplete(false);
    } else {
      setIsProductsComplete(true);
    }
  }, [productList, setIsProductsComplete]);

  useEffect(() => {
    if (isRulesLoading) return;
    let products = rulesData.data.map((rule: any) => ({
      unit: rule[1].unit,
      subunit: rule[1].subunit,
      product: rule[1].product,
      lineOfBusiness: rule[1].lineOfBusiness,
      businessType: rule[1].businessType === 'Admitted' ? true : false,
      complete: true,
      rand: uuidv4(),
      ...rule[2],
    }));
    let newProductListWithDuplicatesMarked: Product[] = findDuplicates(products);
    setProductList(newProductListWithDuplicatesMarked);
  }, [rulesData, isRulesLoading]);

  const handleAdd = () => {
    setProductList((prevState) => prevState.concat([{ ...emptyProduct, rand: uuidv4() }]));
  };

  const scrollToLastProduct = () => {
    const lastChildElement = productRef.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {formId && (
        <Stack direction="row" justifyContent="center">
          <Typography sx={{ textAlign: 'left', fontSize: 24, fontWeight: 700, mr: 'auto' }}>
            Products
          </Typography>
        </Stack>
      )}
      <Stack sx={{ overflow: 'auto' }} ref={productRef}>
        <LoadingOverlay
          active={copyIsLoading || deleteIsLoading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(0, 0, 0, 0.26)',
            }),
            spinner: {},
          }}
        >
          {productList.map((p: Product, index: number) => {
            // need to pass props at string/number level so Object.is evaluates to true for unchanged products
            return (
              <SingleProduct
                key={p.rand}
                rand={p.rand}
                productIndex={index}
                unit={p.unit}
                subunit={p.subunit}
                product={p.product}
                lineOfBusiness={p.lineOfBusiness}
                indexId={p.id}
                businessType={p.businessType}
                isActive={p.isActive}
                complete={p.complete}
                duplicate={p.duplicate}
                formId={formId}
                productHandler={productHandler}
                disabled={disabled}
                userRoles={userRoles}
              />
            );
          })}
        </LoadingOverlay>
        <Button
          onClick={handleAdd}
          variant="outlined"
          disabled={disabled ? true : productList.length === 0 ? false : !isProductsComplete}
          sx={{ marginLeft: 'auto' }}
        >
          Add Product
        </Button>
      </Stack>
    </>
  );
};

export default SelectedProducts;
