import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import Button from '../../components/styledComponents/Button';
import SortableTable from './SortableTable';
import { useAppContext } from '../../Store';
import { OldSelect } from '../../components/styledComponents/Select/Select';
import { useGetForms } from '../../services/getForms';

export type Form = {
  formNumber: string;
  formTitle: string;
  status: string;
  dateModified: string;
  id: string;
  user: {
    email: string;
  };
  userEmail: string;
};

function FormList() {
  const { state } = useAppContext();
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();
  const [emailFilter, setEmailFilter] = useState<string>('');
  const [excludeEmail, setExcludeEmail] = useState<boolean>(false);
  const {
    data: formList,
    isLoading: isFormListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetForms(emailFilter, excludeEmail);

  const forms = useMemo(
    () => (formList ? formList.pages.flatMap((page) => page.forms) : []),
    [formList]
  );

  const maxUsersRef = useRef<string[]>([]);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const users: string[] = useMemo(() => {
    if (forms.length > 0 && filter === 'all') {
      const allUsers = forms.reduce<string[]>((acc, cur) => {
        if (!acc?.find((item) => item === cur?.user?.email)) {
          acc.push(cur?.user?.email);
        }
        return acc;
      }, []);
      maxUsersRef.current = allUsers;
      return allUsers;
    } else return maxUsersRef.current;
  }, [forms, filter]);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    const currentLoadMoreRef = loadMoreRef.current;
    if (currentLoadMoreRef) {
      observer.observe(currentLoadMoreRef);
    }

    return () => {
      if (currentLoadMoreRef) {
        observer.unobserve(currentLoadMoreRef);
      }
    };
  }, [handleObserver]);

  return (
    <Box className="wrapper container">
      <Box className="content-box">
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <h2 className="page-title margin-bottom-0">Forms</h2>
                <p>List of unpublished forms (work in progress)</p>
              </Box>
              <Button
                variant="outlined"
                onClick={() => navigate('/forms/add')}
                sx={{ maxHeight: '45px' }}
              >
                Create Form
              </Button>
            </Stack>
            <Stack component={FormGroup} alignItems="flex-end">
              <Stack direction="row" sx={{ width: 0.48 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter === 'all'}
                      onChange={() => {
                        setEmailFilter('');
                        setExcludeEmail(false);
                        setFilter('all');
                      }}
                    />
                  }
                  label="All"
                  sx={{ whiteSpace: 'nowrap' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter === 'myEdit'}
                      onChange={() => {
                        setEmailFilter(state.auth.user.email);
                        setExcludeEmail(false);
                        setFilter('myEdit');
                      }}
                    />
                  }
                  label="My forms"
                  sx={{ whiteSpace: 'nowrap' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter === 'othersEdit'}
                      onChange={() => {
                        setEmailFilter(state.auth.user.email);
                        setExcludeEmail(true);
                        setFilter('othersEdit');
                      }}
                    />
                  }
                  label="Others' forms"
                  sx={{ whiteSpace: 'nowrap' }}
                />
                <Box sx={{ display: 'contents' }}>
                  <OldSelect
                    id="Filter by user"
                    label="Filter by user"
                    value={excludeEmail ? '' : emailFilter}
                    handleChange={(e) => {
                      setExcludeEmail(false);
                      setEmailFilter(e.target.value);
                      setFilter(e.target.value === state.auth.user.email ? 'myEdit' : '');
                    }}
                    options={users.map((user) => ({ label: user, value: user }))}
                    labelSize="small"
                    selectSize="small"
                  />
                </Box>
              </Stack>
            </Stack>
            {forms.length === 0 && (
              <Stack alignItems="center">
                <Box component="span" sx={{ fontWeight: 700 }}>
                  No results{' '}
                </Box>
                {filter !== 'all' && "(try adjusting your filters)"}
              </Stack>
            )}
            <SortableTable
              rows={forms}
              filter={filter}
              email={state.auth.user.email}
              isLoading={isFormListLoading}
            />
            {hasNextPage && <div ref={loadMoreRef}>Loading more...</div>}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default FormList;
