import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  Stack,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactHookFormSelect } from '../../styledComponents/Select';
import Radio from '../../styledComponents/Radio';
import { formCategoryOptions } from '../../../doc-search/config';
import {
  formUsageCategoryItemsOptions,
  impactOnCoverageItemsOptions,
  variableStaticOptions,
  premiumBearingOptions,
  manualRateConditionOptions,
} from '../constants';
import { useGetOrg } from '../../../services/getOrg';

interface FormDetailsProps {
  control: any;
  errors: any;
  disabled: boolean;
  onChange: any;
  requiredKeys: string[];
}

function FormDetails({ control, errors, disabled, onChange, requiredKeys }: FormDetailsProps) {
  const { data: orgData, isLoading: isOrgDataLoading } = useGetOrg();
  return (
    <>
      {!isOrgDataLoading && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="formDetailsAccordion"
          >
            <Typography variant="h6">Form Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Stack spacing={4} width={1}>
                <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ width: 1 }}>
                  <Box sx={{ flex: 1, minWidth: '22%' }}>
                    <ReactHookFormSelect
                      control={control}
                      defaultValue={''}
                      disabled={disabled}
                      id="form_source"
                      label={requiredKeys.includes('form_source') ? "* Source" : 'Source'}
                      name="form_source"
                      onChange={onChange}
                      errors={errors}
                    >
                      {orgData?.formSourceOptions.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </ReactHookFormSelect>
                  </Box>
                  <Stack spacing={2} direction="row" sx={{ flex: 4 }}>
                    <Radio
                      title={`${requiredKeys.includes('form_usage_category') ? '*' : ''} Usage Category`}
                      options={formUsageCategoryItemsOptions}
                      control={control}
                      id="form_usage_category"
                      errors={errors}
                      disabled={disabled}
                    />
                    <Radio
                      title={`${requiredKeys.includes('impact_on_coverage') ? '*' : ''} Impact on coverage`}
                      options={impactOnCoverageItemsOptions}
                      control={control}
                      id="impact_on_coverage"
                      errors={errors}
                      disabled={disabled}
                    />
                    <Radio
                      title={`${requiredKeys.includes('premium_bearing') ? '*' : ''} Premium bearing`}
                      options={premiumBearingOptions}
                      control={control}
                      id="premium_bearing"
                      required
                      errors={errors}
                      disabled={disabled}
                    />
                  </Stack>
                </Stack>
                <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ width: 1 }}>
                  <Box sx={{ flex: 1, minWidth: '22%' }}>
                    <ReactHookFormSelect
                      id="form_type"
                      label={`${requiredKeys.includes('form_type') ? '*' : ''} Type`}
                      control={control}
                      errors={errors}
                      disabled={disabled}
                      defaultValue={''}
                      name="form_type"
                      onChange={onChange}
                    >
                      {orgData?.formTypeOptions.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </ReactHookFormSelect>
                  </Box>
                  <Stack spacing={2} direction="row" sx={{ flex: 4 }}>
                    <Radio
                      title={`${requiredKeys.includes('form_category') ? '*' : ''} Category`}
                      options={formCategoryOptions}
                      control={control}
                      id="form_category"
                      errors={errors}
                      disabled={disabled}
                    />
                    <Radio
                      title={`${requiredKeys.includes('variable_static') ? '*' : ''} Variable/Static`}
                      options={variableStaticOptions}
                      control={control}
                      id="variable_static"
                      errors={errors}
                      disabled={disabled}
                    />
                    <Radio
                      title={`${requiredKeys.includes('manual_rate_condition') ? '*' : ''} Manual rate condition`}
                      options={manualRateConditionOptions}
                      control={control}
                      id="manual_rate_condition"
                      errors={errors}
                      disabled={disabled}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

export default FormDetails;
