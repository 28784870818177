import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';

import { fetchDocList } from '../../services';
import DocEditContent from '../../components/DocEditContent';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useAppContext } from '../../Store';
import { RESET_VIEW_DOC } from '../../reducer';
import { UserRoles } from '../../constants';
import { notify, getValidationSchemaByRole } from '../../utils/helper';
import { saveDocEditMetaData } from '../../api';
import { useGetOrg } from '../../services/getOrg';
import { Box, Skeleton, Stack } from '@mui/material';

interface DocAddProps {
  defaultValues: any;
}

function DocAdd({ defaultValues }: DocAddProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchHits, setSearchHits] = useState<readonly any[]>([]);
  const navigate = useNavigate();
  const { state, dispatch } = useAppContext();
  const { isLoading: isOrgDataLoading } = useGetOrg();
  const validationSchema = getValidationSchemaByRole(state.auth.roles as UserRoles[]);
  const formKeys = validationSchema?.$_terms?.keys || []
  const requiredKeys : string[]= formKeys
    .filter((k: any) => {
      const flags = k.schema?._flags;
      return flags && Object.keys(flags).length > 0 && flags.presence === 'required';
    })
    .map((k: any) => k.key) || []; 
  const { handleSubmit, control, formState, watch, trigger, setError, clearErrors } = useForm({
    resolver: joiResolver(validationSchema),
    defaultValues: defaultValues,
  });
  const { errors, isValid } = formState;
  const form_number = watch('form_number');

  const [debouncedFormNumber] = useDebounce(form_number, 300);

  useEffect(() => {
    return () => {
      localStorage.removeItem('link');
    };
  }, []);

  useEffect(() => {
    dispatch({ type: RESET_VIEW_DOC });
  }, [dispatch]);

  useEffect(() => {
    const searchForIt = async () => {
      const response = await fetchDocList(`${debouncedFormNumber}`, state);

      const hits = response.search.hits;
      const singleHits = hits.filter(
        (hit, index) =>
          hits.map(({ _source }) => _source.form_number).indexOf(hit._source.form_number) === index
      );
      setSearchHits(singleHits);
    };

    if (String(debouncedFormNumber).length > 4) {
      searchForIt();
    }
  }, [debouncedFormNumber, navigate, state]);

  if (isOrgDataLoading) {
    return (
      <Box className="wrapper container">
        <Box className="content-box">
          <Stack spacing={2} sx={{}}>
            <Stack spacing={1} direction="row" sx={{ pb: 4 }}>
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={70} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={80} />
            </Stack>
            <Stack spacing={2} sx={{ pb: 2 }}>
              <Skeleton variant="rounded" width="50%" height={60} />
              <Skeleton variant="rounded" width="50%" height={60} />
            </Stack>
            <Skeleton variant="rounded" height={40} />
            <Stack spacing={1} direction="row">
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} height={60} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} height={60} />
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }

  const handleSave = async (values: any) => {
    if (isValid) {
      setIsLoading(true);
      const storedLinkData = localStorage.getItem('link');
      const linkData = storedLinkData ? JSON.parse(storedLinkData) : '';
      const response = await saveDocEditMetaData(
        {
          ...values,
        },
        linkData
      );

      let body: DocEditDocumentResponse = await response?.json();
      if (body?.success && body?.data?.id) {
        setIsLoading(false);
        if (response?.redirected === true) {
          notify(
            `The form linked to ${linkData.id} was already created. Loading the form details.`,
            'warn',
            4500,
            {
              onClose: () => navigate(`/forms/${body.data!.id}`),
            }
          );
        } else {
          navigate(`/forms/${body.data.id}`);
        }
      }
    } else {
      trigger();
    }
  };

  return (
    <DocEditContent
      handleSave={handleSubmit(handleSave)}
      title="Add Form"
      open={open}
      setOpen={setOpen}
      control={control}
      errors={errors}
      isLoading={isLoading}
      searchHits={searchHits}
      watch={watch}
      setError={setError}
      clearErrors={clearErrors}
      requiredKeys={requiredKeys}
    />
  );
}

export default DocAdd;
